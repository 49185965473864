$cell-background-color: rgba(33, 150, 243, 0.12);
$cell-text-color: #1976d2;

.vdp-datepicker__calendar {
    border: unset !important;
    background: none !important;

    .month__year_btn,
    .day__month_btn {
        width: 33.333% !important;
    }

    .prev,
    .next {
        display: flex;
        width: 13.333% !important;

        &:not(.disabled):hover {
            border-radius: 0.25rem;
        }

        &::after {
            margin: auto !important;
            position: unset !important;
            border: solid #000 !important;
            border-width: 0 2px 2px 0 !important;
            padding: 3px;
        }
    }

    .prev {
        margin-left: 20%;

        &::after {
            transform: rotate(135deg) !important;
        }
    }

    .next {
        margin-right: 20%;

        &::after {
            transform: rotate(-45deg) !important;
        }
    }

    .cell {

        &.selected,
        &.highlighted {
            background-color: $cell-background-color !important;
            color: $cell-text-color;
            border-radius: 0.25rem;
        }

        &.month,
        &.day {
            &:not(.blank):not(.disabled):not(.selected):hover {
                border: 1px solid transparent !important;
                color: $cell-text-color;
            }
        }
    }
}

.calendar {
    .cell {
        &.highlighted {
            border-radius: 0 !important;

            &.highlight-start {
                background-color: rgba(33, 150, 243, 0.24) !important;
                border-top-left-radius: 0.25rem !important;
                border-bottom-left-radius: 0.25rem !important;
            }

            &.highlight-end {
                background-color: rgba(33, 150, 243, 0.24) !important;
                border-top-right-radius: 0.25rem !important;
                border-bottom-right-radius: 0.25rem !important;
            }

            &.sat {
                border-top-right-radius: 0.25rem !important;
                border-bottom-right-radius: 0.25rem !important;
            }

            &.sun {
                border-bottom-left-radius: 0.25rem !important;
                border-top-left-radius: 0.25rem !important;
            }
        }
    }
}
