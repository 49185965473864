@tailwind utilities;

@import './variables';
@import '~emailmeter-theme/theme/main';
@import '~bulma-switch/dist/css/bulma-switch';
@import '~bulma-tooltip/src/sass/index';
@import '~bulma-checkradio/dist/css/bulma-checkradio';

@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900&display=swap');
@import '~emailmeter-theme/fonts/em-icons/em-icons';

@import './theme';

@import './vue_datepicker';

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#emailmeter-app {
    display: flex;
    min-height: calc(100vh - #{$navbar-height});
    flex-direction: column;
}

.main-container {
    @include desktop {
        margin-left: $sidemenu-width;
        min-height: calc(100vh - #{$navbar-height});
    }

    display: flex;
    flex: 1;
    flex-direction: column;

    section {
        @include mobile {
            padding: 0.8rem;
        }
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        flex: 1;
    }
}

#intercom-container {
    .intercom-app {
        iframe {
            @include touch {
                margin-bottom: 85px;
            }
        }
    }
}

.tabs {
    background-color: rgba(33, 37, 41, 0.9);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    font-size: $size-8;
}

p {
    &:not(:last-child) {
        margin-bottom: 0.5rem;
    }
}

.has-top-bar {
    .navbar {
        top: $topbar-height !important;
    }

    .main-container {
        margin-top: $topbar-height !important;
        min-height: calc(100vh - #{($navbar-height + $topbar-height)}) !important;
    }

    #main-menu {
        min-height: calc(100vh - #{($navbar-height + $topbar-height)}) !important;
    }

    .notification {
        top: calc(#{($navbar-height + $topbar-height)} + 1rem) !important;
    }
}
