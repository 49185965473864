// Import this file to use variables and mixins
@import '~emailmeter-theme/theme/variables';

// Start bulma variables
$footer-padding: 1rem 3rem;
$footer-color: $grey-darker;

$tabs-link-active-border-bottom-color: $yellow;
$tabs-link-active-color: $white;
$tabs-link-padding: 0.75rem 2rem;
$tabs-link-color: rgba($white, 0.4);
$tabs-link-hover-color: $white;
$tabs-border-bottom-width: 2px;
$tabs-border-bottom-color: transparent;
$tabs-link-hover-border-bottom-color: transparent;

$topbar-height: 3.5rem;

$navbar-height: 4rem;
$navbar-item-hover-background-color: none;
$navbar-dropdown-item-hover-background-color: none;
$navbar-dropdown-item-hover-color: $primary;
$navbar-item-img-max-height: 3rem;
// End bulma variables

// Bulma switch
$switch-background: $grey;
// End bulma switch

// Bulma tooltip
$tooltip-font-size: 0.85rem; // FIXME: not applying
$tooltip-padding: 0.25rem 0.5rem;
// End bulma tooltip

$sidemenu-width: 95px;

$metric-sent: #08b545;
$metric-received: #0096fb;

$enterprise-color: #293144;
