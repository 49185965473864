$metric-colors: ("metric-sent": $metric-sent, "metric-received": $metric-received);

@each $name, $color in $metric-colors {
    .has-background-#{$name} {
        background-color: $color;
    }

    .has-text-#{$name} {
        color: $color;
    }
}

.modal {
    &.fade-enter-active,
    &.fade-leave-active {
        transition: opacity 0.5s;
    }

    &.fade-enter,
    &.fade-leave-to {
        opacity: 0;
    }

    &.is-large > .modal-content {
        max-width: 880px;
        width: 100%;
    }
}

.progress::-webkit-progress-value {
    transition: width 0.5s;
}